const initialFilters = {
  search: null,
  startDate: null,
  notready_disposition: 0,
  endDate: null,
  id_publications: null,
  num_disposition: null,
  num_publication: null,
  id_states: null,
  id_types: null
};

export default {
  namespaced: true,
  state: {
    filters: { ...initialFilters },
    publications: [],
    states: [],
    types: [],
    families: [],
    keys: []
  },
  mutations: {
    setInfo( state, payload ) {
      state.publications = payload.publications;
      state.states = payload.states;
      state.types = payload.types;
      state.families = payload.families;
      state.keys = payload.keys;
    },
    set( state, payload ) {
      Object.assign( state.filters, payload );
    }
  },
  actions: {
    clean({ commit }) {
      commit( 'set', initialFilters )
    }
  },
  getters: {
    normalized( state ) {
      var value;
      const filters = {};
      for ( var key in initialFilters ) {
        value = state.filters[key];
        if (( typeof value != 'boolean' && value != null && value !== '' ) || value === true ) {
          filters[key] = state.filters[key];
        }
      }
      return filters;
    }
  }
};
