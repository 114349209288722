import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';

Vue.use( Vuetify );

export default new Vuetify({
    lang: {
      locales: { es },
      current: 'es',
    },
    theme: {
      options: {
        variations: false,
        customProperties: true
      },
      themes: {
        light: {
          primary: '#0071bc',
          secondary: '#3ca1e4',
          tertiary: '#1f34a7',
          dark: '#333333',
          anchor: '#0071bc'
        }
      }
    }
});
