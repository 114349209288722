import store from '../store';

export function dispositionTitle( dis, type, clean ) {

  const status = dis.notready_disposition && !clean && '(Despublicada) ' || '';
  if ( dis.title ) return status + dis.title;

  type = ( type || {
    text: '',
    ...store.state.filters.types.find( a => a.id === dis.id_types )
  }).text;

  var num = [
    dis.num_disposition !== '-' && dis.num_disposition,
    dis.date_publication ? formatDate( dis.date_publication ).split('/').pop() : null
  ].filter( Boolean ).join('/');

  return status + [ type, num ].filter( Boolean ).join(' - ');
}

export function toArray( value ) {
  if ( value == null ) return [];
  if ( typeof value != 'string' && typeof value.length == 'number' )
    return Array.prototype.slice.call( value );
  return [ value ];
}

export function toDate( value ) {
  if ( typeof value === 'string' ) value = value.slice( 0, 10 );
  return new Date( value );
}

export function formatDate( value, options ) {
  return toDate( value ).toLocaleString( navigator.language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...options
  });
}

export function countChars( str, chars ) {
  return (( str || '').match( new RegExp( `[${chars}]`, 'g' )) || '' ).length;
}

export function digits( value, digits ) {
  digits = '0'.repeat( digits || 0 );
  return digits.slice( String( value ).length ) + value;
}

export function translateError( err, base ) {
  return {
    ...err,
    message: ( base || {} )[err.error] || 'Hi ha hagut un error desconegut.'
  };
}

export function required( message ) {
  return function( value ) {
    return !!value || message || 'Required.';
  }
}

// Normalize text

const from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç";
const to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc";
const map = {};

for ( var i = 0, j = from.length; i < j; i++ )
  map[from.charAt(i)] = to.charAt(i);

export const normalize = str => {
  var ret = [];
  for ( var i = 0, j = str.length; i < j; i++ ) {
    var c = str.charAt(i);
    if ( map.hasOwnProperty(c)) ret.push( map[c] );
    else ret.push(c);
  }
  return ret.join('').toLowerCase();
}

export const tableSearch = ( search, key, rows ) => {

  if ( ! search || !key ) return rows;

  search = search
    .split(/\s+/)      // Separamos las palabras por espacios en blanco...
    .filter( Boolean ) // Eliminamos palabras vacias
    .map( normalize )  // Quitamos acentos y símbolos;

  // Find coincidences

  var results = [];
  rows.forEach(( item, column ) => {
    if ( results.indexOf( item ) < 0 ) {
      if ( typeof row[key] !== 'string' ) return;
      column = normalize( row[key] );
      for ( var i = 0; i < search.length; i++ ) {
        if ( column.indexOf( search[i] ) !== -1 ) {
          results.push( item );
          break;
        }
      }
    }
  });

  return results;
}
