import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

Vue.use( Router );

const onlyLogged = mode => ( to, from, next ) => {
  const { userID } = store.state.auth;
  if ( mode ) {
    if ( userID ) next();
    else next('/login');
  } else {
    if ( ! userID ) next();
    else next('/');
  }
}

const onlyAdmins = ( to, from, next ) => {
  if(store.state.auth.user!=null){
  const { role } = store.state.auth.user;
  if ( role == 'admin' ) next();
  else next('/admin');
  }
  else{
    next('/admin');
  }
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Admin/Login.vue'),
    beforeEnter: onlyLogged()
  },
  {
    path: '/recover-password',
    name: 'RecoverPassword',
    component: () => import(/* webpackChunkName: "login" */ '../views/Admin/RecoverPassword.vue'),
    beforeEnter: onlyLogged()
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "main" */ '../views/Main/Index.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "dispositions" */ '../views/Main/Home.vue'),
      },
      {
        path: 'disposicions',
        name: 'Dispositions',
        component: () => import(/* webpackChunkName: "dispositions" */ '../views/Main/Dispositions.vue'),
      },
      {
        path: 'quant-a',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/Main/About.vue')
      },
      {
        path: 'links',
        name: 'Links',
        component: () => import(/* webpackChunkName: "links" */ '../views/Main/Links.vue')
      },
      {
        path: 'ajuda',
        name: 'Help',
        component: () => import(/* webpackChunkName: "help" */ '../views/Main/Help.vue')
      },
      {
        path: 'admin',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Index.vue'),
        beforeEnter: onlyLogged(true),
        children: [
          {
            path: '',
            redirect: '/admin/dispositions'
          },
          {
            path: 'users',
            name: 'UsersTable',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Users.vue'),
            beforeEnter: onlyAdmins
          },
          {
            path: 'dispositions',
            name: 'DispositionsTable',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Dispositions.vue')
          },
          {
            path: 'families',
            name: 'FamiliesTable',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Families.vue'),
            beforeEnter: onlyAdmins
          },
          {
            path: 'keys',
            name: 'KeysTable',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Keys.vue'),
            beforeEnter: onlyAdmins
          },
          {
            path: 'descriptors',
            name: 'DescriptorsTable',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Descriptors.vue'),
            beforeEnter: onlyAdmins
          },
          {
            path: 'publications',
            name: 'PublicationsTable',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Publications.vue'),
            beforeEnter: onlyAdmins
          },
          {
            path: 'states',
            name: 'StatesTable',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/States.vue'),
            beforeEnter: onlyAdmins
          },
          {
            path: 'types',
            name: 'TypesTable',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Types.vue'),
            beforeEnter: onlyAdmins
          },
          {
            path: 'profile',
            name: 'Profile',
            component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/Profile.vue')
          }
        ]
      },
    ]
  },
  {
    path: '*',
    name: '404',
    redirect: '/'
  }
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
