export default {
  namespaced: true,
  state: {
    categories: true,
    filters: false,
    submenu: true,
    headerHeight: 60,
    subHeaderHeight: 60,
    footerHeight: 60,
    drawerWidth: 220,
    alert: {
      show: false,
      type: 'error',
      message: ''
    }
  },
  mutations: {
    setCategories( state, open ) {
      state.categories = open;
    },
    setFilters( state, open ) {
      state.filters = open;
    },
    setSubmenu( state, open ) {
      state.submenu = open;
    },
    setAlert( state, payload ) {
      state.alert = { ...payload, show: true };
    }
  }
};
