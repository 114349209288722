//import { LOGIN_ERROR } from '@/utils/constants';
//import { translateError } from '@/utils';

import { getItems, insertItem, updateItem, removeItem, exportItems } from '@/utils/requests';

export default {
  namespaced: true,
  state: {
    loading: false,
    users: { page: 1, pages: 0, items: [], search: '' },
    dispositions: { page: 1, pages: 0, items: [], search: '' },
    families: { page: 1, pages: 0, items: [], search: '' },
    keys: { page: 1, pages: 0, items: [], search: '' },
    descriptors: { page: 1, pages: 0, items: [], search: '' },
    publications: { page: 1, pages: 0, items: [], search: '' },
    states: { page: 1, pages: 0, items: [], search: '' },
    types: { page: 1, pages: 0, items: [], search: '' }
  },
  mutations: {
    setLoading( state, value ) {
      state.loading = value;
    },
    setSearch( state, { type, search }) {
      state[type] = { ...state[type], search };
    },
    set( state, payload ) {
      state[payload.type] = { ...state[payload.type], ...payload };
    },
    add( state, payload ) {
      payload.items = state[payload.type].items.concat(
        payload.items.filter( a => ! state[payload.type].items.find( r => r.id === a.id ))
      );
      state[payload.type] = payload;
    },
    update( state, payload ) {
      payload.items.forEach(( item, index ) => {
        index = state[payload.type].items.findIndex( a => a.id === item.id );
        if ( index !== -1 ) state[payload.type].items.splice( index, 1, item );
      });
      state[payload.type] = payload;
    },
    remove( state, payload ) {
      payload.items.forEach(( item, index ) => {
        index = state[payload.type].items.findIndex( a => a.id === item.id );
        if ( index !== -1 ) state[payload.type].items.splice( index, 1 );
      });
      state[payload.type] = payload;
    }
  },
  actions: {
    validateRequest({ state, commit }, { type, options }) {
      options = options || {};
      options.page = options.page || state[type].page;
      options.search = options.search || state[type].search;
      if ( options.page > 0 ) return options;
      return false;
    },
    // Users
    getUsers( ctx, options ) {
      return getItems( ctx, '/user', 'users', options );
    },
    insertUser( ctx, data ) {
      return insertItem( ctx, '/user', 'users', data );
    },
    updateUser( ctx, data ) {
      return updateItem( ctx, '/user', 'users', data );
    },
    removeUser( ctx, id ) {
      return removeItem( ctx, '/user', 'users', id );
    },
    // Dispositions
    getDispositions( ctx, options ) {
      return getItems( ctx, '/disposition', 'dispositions', options );
    },
    downloadDispositions( ctx, options ) {
      return exportItems( ctx, '/disposition', 'dispositions', options );
    },
    insertDisposition( ctx, data ) {
      return insertItem( ctx, '/disposition', 'dispositions', data );
    },
    updateDisposition( ctx, data ) {
      return updateItem( ctx, '/disposition', 'dispositions', data );
    },
    removeDisposition( ctx, id ) {
      return removeItem( ctx, '/disposition', 'dispositions', id );
    },
    // Families
    getFamilies( ctx, options ) {
      return getItems( ctx, '/family', 'families', options );
    },
    insertFamily( ctx, data ) {
      return insertItem( ctx, '/family', 'families', data );
    },
    updateFamily( ctx, data ) {
      return updateItem( ctx, '/family', 'families', data );
    },
    removeFamily( ctx, id ) {
      return removeItem( ctx, '/family', 'families', id );
    },
    // Keys
    getKeys( ctx, options ) {
      return getItems( ctx, '/family-key', 'keys', options );
    },
    insertKey( ctx, data ) {
      return insertItem( ctx, '/family-key', 'keys', data );
    },
    updateKey( ctx, data ) {
      return updateItem( ctx, '/family-key', 'keys', data );
    },
    removeKey( ctx, id ) {
      return removeItem( ctx, '/family-key', 'keys', id );
    },
    // Descriptors
    getDescriptors( ctx, options ) {
      return getItems( ctx, '/descriptor', 'descriptors', options );
    },
    insertDescriptor( ctx, data ) {
      return insertItem( ctx, '/descriptor', 'descriptors', data );
    },
    updateDescriptor( ctx, data ) {
      return updateItem( ctx, '/descriptor', 'descriptors', data );
    },
    removeDescriptor( ctx, id ) {
      return removeItem( ctx, '/descriptor', 'descriptors', id );
    },
    // Publications
    getPublications( ctx, options ) {
      return getItems( ctx, '/publication', 'publications', options );
    },
    insertPublication( ctx, data ) {
      return insertItem( ctx, '/publication', 'publications', data );
    },
    updatePublication( ctx, data ) {
      return updateItem( ctx, '/publication', 'publications', data );
    },
    removePublication( ctx, id ) {
      return removeItem( ctx, '/publication', 'publications', id );
    },
    // States
    getStates( ctx, options ) {
      return getItems( ctx, '/state', 'states', options );
    },
    insertState( ctx, data ) {
      return insertItem( ctx, '/state', 'states', data );
    },
    updateState( ctx, data ) {
      return updateItem( ctx, '/state', 'states', data );
    },
    removeState( ctx, id ) {
      return removeItem( ctx, '/state', 'states', id );
    },
    // Types
    getTypes( ctx, options ) {
      return getItems( ctx, '/type', 'types', options );
    },
    insertType( ctx, data ) {
      return insertItem( ctx, '/type', 'types', data );
    },
    updateType( ctx, data ) {
      return updateItem( ctx, '/type', 'types', data );
    },
    removeType( ctx, id ) {
      return removeItem( ctx, '/type', 'types', id );
    }
  }
};
