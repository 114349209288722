import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/sticky';
import '@/styles/main.css';
import moment from 'moment-timezone'

moment.tz.setDefault('Europe/Madrid');
Vue.config.productionTip = false;

// Initialize auth
store.dispatch( 'auth/refreshToken' );

new Vue({
  router,
  store,
  vuetify,
  render: h => h( App )
}).$mount('#app')
