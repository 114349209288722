const isProd = process.env.NODE_ENV == 'production';

export const BASE_URL = 'http://localhost:4545/';
//export const BASE_API = isProd ? 'https://api.legis.inmovens.com/' : 'http://localhost:4545/local';
//export const BASE_API = isProd ? 'https://api.legis.inmovens.com/' : 'http://localhost:4545/pro';
export const BASE_API = isProd ? 'https://apilegisali.uab.cat/' : 'http://localhost:4545/real';

// ERRORS

export const LOGIN_ERROR = {
  1: 'Hi ha hagut un error desconegut.',
  2: 'Usuari o contrasenya incorrecta.'
}

export const RECOVER_ERROR = {
  1: 'Hi ha hagut un error desconegut.',
  2: 'Error al recuperar la contrasenya del seu usuari.'
}
