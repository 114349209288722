import api from '@/utils/api';

export const requestParams = ({ filters, categories, families, keys, search, orderby, order }) => {

  var key, value;
  const params = {};

  if ( search )
    params.search = btoa(search);

  for ( key in filters ) {

    value = filters[key];

    if ( key === 'search' ) {

      params[key] = btoa(value);

    } else if ( key === 'startDate' ) {

      value = new Date(value).toISOString();
      if ( params.date_publication ) {
        params.date_publication = { between: [ value, params.date_publication.lte ]};
      } else {
        params.date_publication = { gte: value };
      }

    } else if ( key === 'endDate' ) {
      value = new Date(value).toISOString();
      if ( params.date_publication ) {
        params.date_publication = { between: [ params.date_publication.gte, value ]};
      } else {
        params.date_publication = { lte: value };
      }
    } else {
      params[key] = value;
    }
  }

  // Categories

  if ( categories ) {
    categories = categories.filter( cat => typeof cat === 'number' );
    categories.length && ( params.descriptors = categories );
  }

  // Claus

  if ( keys ) {
    keys = keys.filter( cat => typeof cat === 'number' );
    keys.length && ( params.keys = keys );
  }

  // Families

  if ( families ) {
    families = families.filter( cat => typeof cat === 'number' );
    families.length && ( params.families = families );
  }

  // Order

  if ( orderby ) params.orderby = orderby;
  if ([ 'asc', 'desc' ].includes( order )) params.order = order;

  return params;
}

export const getItems = ( ctx, path, type, options ) => new Promise(( resolve, reject ) => {
  Promise.resolve( ctx.dispatch( 'validateRequest', { type, options })).then( options => {
    if ( options ) {

      ctx.commit( 'setLoading', true );
      const params = requestParams( options );

      api.get( `${ path }/page/${ options.page }`, { params }).then( res => {

        if ( res.data.error )
          return reject( res.data );

        const payload = {
          type,
          page: options.page,
          total: res.data.object.total,
          pages: res.data.object.pages,
          items: res.data.object.data
        };

        ctx.commit( options.replace ? 'set' : 'add', payload );
        resolve( res.data.object );
      })
      .catch( reject )
      .finally(() => ctx.commit( 'setLoading', false ));

    } else {
      reject( new Error('Invalid request.'));
    }
  })
});

export const exportItems = ( ctx, path, type, options ) => new Promise(( resolve, reject ) => {
  Promise.resolve( ctx.dispatch( 'validateRequest', { type, options })).then( options => {
    if ( options ) {

      ctx.commit( 'setLoading', true );
      api.get( `${ path }/download/${ options.page }`).then( res => {
        if ( res.data.error ) return reject( res.data.error );
        resolve( res.data.object );
      })
      .catch( reject )
      .finally(() => ctx.commit( 'setLoading', false ));

    } else {
      reject( new Error('Invalid request.'));
    }
  })
});

export const insertItem = ( ctx, path, type, data ) => new Promise(( resolve, reject ) => {

  var result;
  const getter = 'get' + type.charAt(0).toUpperCase() + type.slice(1);
  ctx.commit( 'setLoading', true );

  api.post( `${ path }`, data ).then( res => {
    if ( res.data.error ) return reject( res.data );
    result = res.data.object;
  })
  .then(() => ctx.dispatch( getter, { replace: true }))
  .then(() => resolve( result ))
  .catch( reject )
  .finally(() => ctx.commit( 'setLoading', false ));
});

export const updateItem = ( ctx, path, type, data ) => new Promise(( resolve, reject ) => {

  const { id } = data;
  const getter = 'get' + type.charAt(0).toUpperCase() + type.slice(1);
  var result;

  ctx.commit( 'setLoading', true );

  api.put( `${ path }/${ id }`, data ).then( res => {
    if ( res.data.error ) return reject( res.data );
    result = res.data.object;
  })
  .then(() => ctx.dispatch( getter, { replace: true }))
  .then(() => resolve( result ))
  .catch( reject )
  .finally(() => ctx.commit( 'setLoading', false ));
});

export const removeItem = ( ctx, path, type, id ) => new Promise(( resolve, reject ) => {

  const getter = 'get' + type.charAt(0).toUpperCase() + type.slice(1);
  var result;

  ctx.commit( 'setLoading', true );

  api.delete(`${ path }/${ id }`).then( res => {
    if ( res.data.error ) return reject( res.data );
    result = res.data.object;
  })
  .then(() => ctx.dispatch( getter, { replace: true }))
  .then(() => resolve( result ))
  .catch( reject )
  .finally(() => ctx.commit( 'setLoading', false ));
});
