<template>
  <router-view/>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  computed: mapState( 'auth', [ 'userID' ]),
  watch: {
    userID( value ) {
      value && this.$store.dispatch( 'auth/fetchUser' );
    }
  },
  created() {
    this.$store.dispatch( 'auth/refreshToken' );
  }
};
</script>
