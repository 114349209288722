import { LOGIN_ERROR } from '@/utils/constants';
import { translateError } from '@/utils';
import api from '@/utils/api';

// Config

export default {
  namespaced: true,
  state: {
    loading: false,
    user: null,
    userID: null,
    role: null,
    token: null,
    isAdmin: false
  },
  mutations: {
    setLoading( state, value ) {
      state.loading = value;
    },
    setUser( state, user ) {
      state.user = user;
      localStorage.setItem( 'accessUserRole', user.role );
    },
    setToken( state, data ) {
      api.setToken( data );
      if ( data ) {
        state.token = data.token;
        state.userID = data.userID;
        state.role = data.role;
        state.isAdmin = data!=null && data.role === 'admin';
      } else {
        state.token = state.userID = state.user = state.role = null;
        state.isAdmin = false;
      }
    }
  },
  actions: {
    refreshToken({ commit, dispatch }) {
      commit( 'setToken', {
        token: localStorage.getItem('accessToken') || null,
        userID: localStorage.getItem('accessUser') || null,
        role: localStorage.getItem('accessUserRole') || null
      });
      dispatch('fetchUser');
    },
    login({ commit, dispatch }, data ) {
      return new Promise(( resolve, reject ) => {

        commit( 'setToken' ); // Reset token
        commit( 'setLoading', true );

        api.post( '/user/login', data ).then( res => {
          if ( res.data.error ) return reject( translateError( res.data, LOGIN_ERROR ));
          commit( 'setToken', res.data.object );
          resolve( res.data.object );
        })
        .catch( err => reject( translateError( err, LOGIN_ERROR )))
        .finally(() => commit( 'setLoading', false ));
      });
    },
    recoverPassword({ commit }, data ) {
      return new Promise(( resolve, reject ) => {

        commit( 'setToken' ); // Reset token
        commit( 'setLoading', true );

        api.post( '/user/password/recover', data ).then( res => {
          //if ( res.data.error ) return reject( translateError( res.data, RECOVER_ERROR ));
          if ( res.data.error ) return reject( res.data );
          resolve( res.data.error );
        })
        //.catch( err => reject( translateError( err, RECOVER_ERROR )))
        .catch( reject )
        .finally(() => commit( 'setLoading', false ));
      });
    },
    resetPassword({ commit }, data ) {
      return new Promise(( resolve, reject ) => {

        commit( 'setToken' ); // Reset token
        commit( 'setLoading', true );

        api.post( '/user/password/reset', data ).then( res => {
          //if ( res.data.error ) return reject( translateError( res.data, RECOVER_ERROR ));
          if ( res.data.error ) return reject( res.data );
          resolve( res.data.error );
        })
        //.catch( err => reject( translateError( err, RECOVER_ERROR )))
        .catch( reject )
        .finally(() => commit( 'setLoading', false ));
      });
    },
    logout({ commit }) {
      commit('setToken');
    },
    fetchUser({ dispatch, commit, state }) {
      return new Promise(( resolve, reject ) => {

        if ( ! state.userID ) {
          commit( 'setToken' ); // Reset all
          return resolve( null );
        }

        commit( 'setLoading', true );
        api.get( '/user/' + state.userID ).then( res => {
          if ( res.data.error ) {
            commit( 'setToken' ); // Reset all
            reject( res.data );
          } else {
            commit( 'setUser', res.data.object );
            resolve( res.data.object );
          }
        })
        .catch( err => {
          commit( 'setToken' ); // Reset all
          reject( err );
        })
        .finally(() => commit( 'setLoading', false ));
      });
    }
  }
};
