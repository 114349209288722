import Vue from 'vue';
import Vuex from 'vuex';

// MODULES
import app from './app';
import filters from './filters';
import categories from './categories';
import auth from './auth';
import api from './api';

// UTILS

import { requestParams } from '@/utils/requests';

// AXIOS CONFIG

import axios from 'axios';
import { BASE_API } from '@/utils/constants';

const request = axios.create({
  baseURL: BASE_API,
  headers: { 'Content-type': 'application/json' }
});

Vue.use( Vuex );

const store = new Vuex.Store({
  state: {
    page: 1,
    home: false,
    loading: false,
    downloading: false,
    printing: false,
    news: null,
    results: [],
    total : 0,
    pages : 1,
    infiniteID: +new Date(),
    orderby: 'date_publication',
    order: 'desc'
  },
  modules: {
    app,
    api,
    auth,
    filters,
    categories
  },
  mutations: {
    set( state, results ) {
      state.results = results;
    },
    setPageData( state, res ) {
      state.total = res.total;
      state.pages = res.pages;
    },
    setPage( state, page ) {
      state.page = Math.max( 1, page );
    },
    setLoading( state, value ) {
      state.loading = value;
    },
    setDownoading( state, value ) {
      state.downloading = value;
    },
    setPrinting( state, value ) {
      state.printing = value;
    },
    setNews( state, results ) {
      state.news = results;
    },
    setHome( state, value ) {
      state.home = value;
    },
    setInfinite( state ) {
      state.infiniteID++;
    },
    setOrder( state, payload ) {
      state.orderby = payload.orderby;
      state.order = payload.order;
    },
    add( state, results ) {
      results = ( results || [] ).filter( a => !state.results.find( r => r.id === a.id ));
      state.results = state.results.concat( results );
    }
  },
  actions: {
    fetch({ dispatch, commit, state, getters }, options ) {
      return new Promise(( resolve, reject ) => {

        // Prepare options

        options = {
          page: state.page,
          categories: state.categories.selected,
          filters: getters['filters/normalized'],
          orderby: state.orderby,
          order: state.order,
          ...options
        };

        if ( options.page < 1 ) return reject();
        if ( state.loading ) return resolve();

        const dispatcher = options.news
          ? 'loadNews'
          : 'loadDispositions';

        commit( 'setLoading', true );

        Promise
          .resolve( dispatch( 'loadHome' ))
          .then(() => dispatch( dispatcher, options ))
          .then( resolve )
          .catch( err => {
            console.error( err );
            reject( err );
          })
          .finally(() => {
            commit( 'setLoading', false )
          });
      });
    },
    exportData({ dispatch, commit, state, getters }, options ) {
      return new Promise(( resolve, reject ) => {

        // Prepare options

        options = {
          page: state.page,
          categories: state.categories.selected,
          filters: getters['filters/normalized'],
          orderby: state.orderby,
          order: state.order,
          ...options
        };

        console.log( "ExportData Store " + options );

        if ( state.downloading ) return resolve();

        commit( 'setDownoading', true );

        dispatch( 'downloadDispositions', options )
          .then( resolve )
          .catch( err => {
            console.error( err );
            reject( err );
          })
          .finally(() => {
            commit( 'setDownoading', false )
          });
        });
    },
    exportHomeData({ dispatch, commit, state, getters }) {
      return new Promise(( resolve, reject ) => {

        if ( state.downloading ) return resolve();

        commit( 'setDownoading', true );

        dispatch( 'downloadHome' )
          .then( resolve )
          .catch( err => {
            console.error( err );
            reject( err );
          })
          .finally(() => {
            commit( 'setDownoading', false )
          });
        });
    },
    printData({ dispatch, commit, state, getters }, options ) {
      return new Promise(( resolve, reject ) => {

        // Prepare options

        options = {
          page: state.page,
          categories: state.categories.selected,
          filters: getters['filters/normalized'],
          orderby: state.orderby,
          order: state.order,
          ...options
        };

        console.log( "PrintData Store " + options );

        if ( state.printing ) return resolve();

        commit( 'setPrinting', true );

        dispatch( 'printDispositions', options )
          .then( resolve )
          .catch( err => {
            console.error( err );
            reject( err );
          })
          .finally(() => {
            commit( 'setPrinting', false )
          });
        });
    },
    printHomeData({ dispatch, commit, state, getters }) {
      return new Promise(( resolve, reject ) => {

        if ( state.printing ) return resolve();

        commit( 'setPrinting', true );

        dispatch( 'printHome' )
          .then( resolve )
          .catch( err => {
            console.error( err );
            reject( err );
          })
          .finally(() => {
            commit( 'setPrinting', false )
          });
        });
    },
    reset({ dispatch, commit }) {
      commit( 'set', [] );
      commit( 'setPage', 1 );
      commit( 'setInfinite' );
    },
    loadDispositions({ dispatch, commit, state }, options ) {
      return dispatch( 'getDispositions', options ).then( results => {
        if ( options.replace ) commit( 'set', results.data );
        else commit( 'add', results.data );
        commit( 'setPageData', results );
        return results.data;
      });
    },
    loadHome({ dispatch, commit, state }) {

      if ( state.home ) return;
      commit( 'setHome', true );

      return dispatch( 'getHome' ).then( res => {

        // Normalize and add categories

        var families = res.families.map( a => ({
          id: `family_${a.id}`,
          type: 0,
          text: a.family,
          text_es: a.esfamily,
          position: a.position,
          count: 0
        }));

        var keys = res.keys.map( a => ({
          id: `key_${a.id}`,
          type: 1,
          parent: `family_${a.id_families}`,
          text: a.keyword,
          text_es: a.eskeyword,
          position: a.position,
          count: 0
        }));

        var descriptors = res.descriptors.map( a => ({
          id: a.id,
          type: 2,
          parent: `key_${a.id_keys}`,
          text: a.descriptor,
          text_es: a.esdescriptor,
          aux: a.clau_aux, // ??
          position: a.position,
          count: 0
        }));

        // Filter invalid descriptors and invalid keys

        descriptors = descriptors.filter( des => {
          return keys.some( key => key.id === des.parent );
        });

        keys = keys.filter( key => {
          return (
            families.some( fam => key.parent === fam.id )
            && descriptors.some( des => key.id === des.parent )
          );
        });

        families = families.filter( family => {
          return keys.some( key => family.id === key.parent );
        });

        // Add categories

        commit(
          'categories/set',
          [ families, keys, descriptors ].flat(),
          { root: true }
        );

        // Normalize filter types

        commit( 'filters/setInfo', {
          publications: res.publications.map( p => ({
            id: p.id,
            text: p.shorttitle,
            title: p.title,
            title_es: p.estitle
          })),
          states: res.states.map( s => ({
            id: s.id,
            text: s.status,
            text_es: s.esstatus
          })),
          types: res.types.map( t => ({
            id: t.id,
            text: t.typedoc,
            text_es: t.estypedoc
          })),
          families: res.families.map( f => ({
            id: f.id,
            text: f.family,
            text_es: f.esfamily
          })),
          keys: res.keys.map( k => ({
            id: k.id,
            text: k.keyword,
            text_es: k.eskeyword,
            parent: k.id_families
          }))
        },{ root: true });

      }).catch( err => {
        commit( 'setHome', false );
        return err;
      })
    },
    loadNews({ dispatch, commit }) {
      return dispatch( 'getNews' ).then( results => {
        commit( 'setNews', results );
        return results;
      });
    },
    getDispositions( ctx, { page, filters, categories, orderby, order }) {

      var path = '/disposition';
      if ( page > 0 ) path += `/page/${page}`;
      const params = requestParams({ filters, categories, orderby, order });

      return request.get( path, { params })
        .then( res => {
          if ( res.data.error ) throw res.data;
          return res.data.object;
        });
    },
    downloadDispositions( ctx, { page, filters, categories, orderby, order }) {

      var path = '/disposition/download';
      const params = requestParams({ filters, categories, orderby, order });

      // Request

      return request.get( path, { params, responseType: 'blob' })
        .then( res => {

          console.log( res.data );
          const link = document.createElement('a');
          const url = URL.createObjectURL( new Blob([ res.data ], {
              type: 'application/vnd.ms-excel'
          }));

          link.href = url;
          link.setAttribute( 'download', 'export-dispositions.xlsx' );
          document.body.appendChild( link );
          link.click();
          document.body.removeChild( link );

          return res.data.object;
        });
    },
    downloadHome() {

      var path = '/disposition/download/news';

      // Request

      return request.get( path, { responseType: 'blob' })
        .then( res => {

          console.log( res.data );
          const link = document.createElement('a');
          const url = URL.createObjectURL( new Blob([ res.data ], {
              type: 'application/vnd.ms-excel'
          }));

          link.href = url;
          link.setAttribute( 'download', 'export-dispositions.xlsx' );
          document.body.appendChild( link );
          link.click();
          document.body.removeChild( link );

          return res.data.object;
        });
    },
    printDispositions( ctx, { page, filters, categories, orderby, order }) {

      var path = '/disposition/print';
      const params = requestParams({ filters, categories, orderby, order });

      // Request

      return request.get( path, { params })
        .then( res => {

          console.log( res.data );
          const link = document.createElement('a');
          const url = URL.createObjectURL( new Blob([ res.data ], {
              type: 'text/html'
          }));

          link.href = url;
          link.setAttribute( 'download', 'export-dispositions.html' );
          document.body.appendChild( link );
          link.click();
          document.body.removeChild( link );

          return res.data.object;
        });
    },
    printHome() {

      var path = '/disposition/print/news';

      // Request

      return request.get( path )
        .then( res => {

          console.log( res.data );
          const link = document.createElement('a');
          const url = URL.createObjectURL( new Blob([ res.data ], {
              type: 'text/html'
          }));

          link.href = url;
          link.setAttribute( 'download', 'export-dispositions.html' );
          document.body.appendChild( link );
          link.click();
          document.body.removeChild( link );

          return res.data.object;
        });
    },
    uploadFile( ctx, data ) {
      var path = '/upload';
      return request.post( path, data )
        .then( res => {
          console.log( res );
          return res.data.object;
        });
    },
    getNews() {
      return request.get('/disposition/news')
        .then( res => {
          if ( res.data.error ) throw res.data;
          return res.data.object;
        });
    },
    getHome() {
      return request.get('/home')
        .then( res => {
          if ( res.data.error ) throw res.data;
          return res.data.object;
        });
    },
    sendMail({ commit }, data ) {
      commit( 'setLoading', true );
      return request.post( '/contact', data )
        .then( res => {
          if ( res.data.error ) throw res.data;
          return res.data.object;
        })
        .finally(() => commit( 'setLoading', false ));
    }
  }
});

export default store;
