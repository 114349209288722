export default {
  namespaced: true,
  state: {
    selected: [],
    categories: []
  },
  mutations: {
    set( state, categories ) {
      state.categories = categories;
    },
    add( state, categories ) {
      state.categories = state.categories.concat(
        categories.filter( cat => !state.categories.find( a => a.id === cat.id ))
      );
    },
    setSelected( state, selected ) {
      state.selected = Array.isArray( selected )
        ? selected
        : [ selected ];
    },
    select( state, category ) {
      if ( ! state.selected.includes( category )) {
        state.selected.push( category );
      }
    },
    unselect( state, category ) {
      const index = state.category.indexOf( category );
      if ( index > -1 ) state.category.splice( index, 1 );
    }
  }
};
