import axios from 'axios';
import { BASE_API } from '@/utils/constants';

const api = axios.create({
  baseURL: BASE_API,
  headers: { 'Content-type': 'application/json' }
});

api.setToken = data => {
  console.log("Data: " + data)
  if ( data ) {
    localStorage.setItem( 'accessToken', data.token );
    localStorage.setItem( 'accessUser', data.userID );
    //localStorage.setItem( 'accessUserRole', data.role );
    api.defaults.headers.common.authorization = 'Bearer ' + data.token;
  } else {
    localStorage.removeItem( 'accessToken' );
    localStorage.removeItem( 'accessUser' );
    localStorage.removeItem( 'accessUserRole' );
    api.defaults.headers.common.authorization = null;
  }
}

export default api;
